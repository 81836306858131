.error {
    height: 380px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.description {
    font-size:35px;
    color: #ffffff;
    margin-top: 10px;
}
.error-pic {
    height: 130px;
    width: 130px;
    background:url('../../assets/images/error.png');
    background-size:contain
}