.line-pic {
    height: 200px;
    width: 345px;
    background: red;
    margin-top: 10px;
    margin-bottom: 10px;
}
.chart-title {
    width: 330px;
    margin-bottom: 10px;
    margin-top: 20px;
    position: relative;
    padding-left: 20px;
}

.chart-title:before {
    content:'';
    height: 12px;
    width: 12px;
    background: rgb(34, 107, 241);
    border-radius: 6px;
    position: absolute;
    left: 2px;
    top:1px
}