.card {
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
}
.card-border {
  border: solid 1px #474343;
}

.card-content {
  width: 100%;
  height: 100%;
  display: flex;
}
