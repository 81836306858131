.tab-bar {
    height: 60px;
    width: 375px;
    background: rgb(0, 0, 0);
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#fff;
    border-top: 1px solid rgba(238, 238, 238, 0.39);
    padding-left: 10px;
    padding-right: 10px;
    .tab-bar-item {
        height: 45px;
        min-width: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .tab-bar-item.selected {
        color: #1296db;
    }
    
}