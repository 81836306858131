.workStation-text {
  font-size: 16px;
}

.workStation-testing-image {
  width: 265px;
  height: 265px;
  padding-top: 10px;
}

.workStation-testing-result {
  color: #05d380;
  font-weight: bold;
  font-size: 40px;
}

.workStation-testing-NG {
  color: #ff0000;
}

.workStation-robot-status-value {
  font-size: 18px;
}
