.error-tip{
    font-size: 18px;
    color: #fff;
    line-height: 40px;
    opacity: 0.8;
    margin-bottom: 20px;
}
.block-title {
    font-size:20px;
    color: #fff;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}
.block-title::after {
    content:'';
    position: absolute;
    width: 100px;
    height: 1px;
    background: rgb(202, 200, 200);
    top:10px;
    left: 5%;
    opacity: 0.8;
    
}

.block-title::before{
    content:'';
    position: absolute;
    width: 100px;
    height: 1px;
    background: rgb(202, 200, 200);
    top:10px;
    right: 5%;
    opacity: 0.8;

}