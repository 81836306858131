.homeEdition2-bottom {
  display: flex;
  // height: 300px;

  .homeEdition2-bottom-left {
    width: 25%;
    // background-color: red;

    .homeEdition2-bottom-left-list {
      // background-color: red;
      padding: 15px 0 0 15px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 376px;

      .homeEdition2-bottom-left-list-button {
        width: 85%;
        height: 40px;
        background-color: #1890ff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        color: #fff;
        font-size: 18px;
      }

      .button-marginTop {
        margin-top: 40px;
      }
    }
  }

  .homeEdition2-bottom-right {
    width: 75%;
    // background-color: yellow;
  }
}
