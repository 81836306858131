.shoe-type-name {
    font-size: 30px;    
    padding-left: 17px;
    padding-top: 5px;                       
}
.shoe-size {
    font-size: 20px;
    margin-left: 30px;                                                                                                
}
.shoe-right{
    font-size:20px;                                                                                                
    margin-left: 10px;                                                                                                
}
.robot-status-item {
  width: 85px;      
  display: flex;
  flex-direction: column;
  align-items: center;
  p{
    margin-top: 10px; 
    font-size:16px;                                                                                                
  }                                                                                           
}
.toggle-btn {
  color: #fff;
  text-align: center;
  line-height: 40px;
} 