.base-layout {
  width: 375px;
}

.base-layout-content {
  margin: 0px auto 0 auto;
  width: 355px;
  padding-bottom: 10px;
}

.line-layout {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.line-layout:nth-last-child(1) {
  margin-bottom: 0;
}

.easy-grid-layout {
  margin: 0 auto;
  display: grid;
  align-items: center;
  align-items: center;
  justify-content: center;

  .item-bg {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .item-border-bottom {
    border-bottom: 1px #474343 solid;
  }

  .item-border-right {
    border-right: 1px #474343 solid;
  }

  .item-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.center-layout {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: solid 1px #474343;
  background: url(../assets/images/cardBackGround.png);
}

.layout-container {
  width: 1890px;
  height: 900px;
}

.container {
  background: rgb(123, 255, 0);
}

.params-panel {
  width: 200px;
  background: rgba(0, 0, 0, 0.829);
  position: fixed;
  right: 0;
  top: 50px;
  color: #fff;

  button {
    cursor: pointer;
  }
}

.reference-line {
  width: 1920px;
  height: 1px;
  top: 1080px;
  position: fixed;
  background: rgb(221, 219, 219);
}
