.live-cover {
    height:90px;
    width: 160px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-mask{
        background: rgba(255, 255, 255, 0.199);
        height: 180px;
        width: 345px;
        position: absolute;
        top:0;
        z-index: 999;
    }
    img {
        object-fit:contain;
    }

    // background: red;
}

.camera-name {
    font-size:16px;
    span{
        font-size: 14px;
    }
}

.live-preview-btns {
    display: flex;
    div{
        margin-left: 5px;
    }
}

.full-live-container {

    background: red;
    position: fixed;
    left: 0;
    top:0;
    z-index: 999;
}

.show {
    display: block;
    transition: all 2s;

}
.hide {
    display: none;
    transition: all 2s;


}