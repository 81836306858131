.export-button {
  width: 55px;
  height: 55px;
  background: rgb(255, 255, 255);
  border-radius: 100px;
  position: fixed;
  bottom: 100px;
  right: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  font-size: 10px;
  color: #333;
  > div {
    margin-top: 10px;
  }
  p {
    position: absolute;
    height: 20px;
    width: 50px;
    top: 40px;
    border-radius: 7px;
    border: 1px solid rgb(71, 71, 71);
    background: #fff;
    text-align: center;
    line-height: 18px;
  }
}
